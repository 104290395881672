import axios from "axios";
import { BASE_URL } from "../../Helper/Helper";

const ServiceState = {
  addService: async (requestOptions) => {
    // FETCH API CALL For all notes
    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/create-service`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    return datas;
  },

  getService: async () => {
    // FETCH API CALL For all notes
    const response = await axios.get(`${BASE_URL}/api/v1/admin/get-service`, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    // console.log("response", response);
    let datas = response.data.data;
    // console.log("datas", datas);
    return datas;
  },

  deleteService: async (id) => {
    // FETCH API CALL For all notes
    const response = await axios.delete(
      `${BASE_URL}/api/v1/admin/delete-service/${id}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    let datas = response.data.data;
    return datas;
  },

  updateService: async (id, requestOptions) => {
    // FETCH API CALL For all notes
    await axios.put(
      `${BASE_URL}/api/v1/admin/update-service/${id}`,
      requestOptions,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
  },

  updateServiceById: async (id, updatedFiles) => {
    // FETCH API CALL For all notes
    await axios.put(
      `${BASE_URL}/api/v1/admin/single-service/${id}`,
      updatedFiles,
      {
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      }
    );
  },
};

export default ServiceState;
